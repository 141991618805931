/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useContext } from 'react';
import { Layout, Menu } from 'antd';
import { useRouter } from 'next/router';
import OrgChartIcon from '@assets/svgs/org-chart-icon.svg';
import HomeIcon from '@assets/svgs/home-icon.svg';
import makeTypedTranslation from '@hooks/typedTranslations/makeTypedTranslation';
import EmployeeIcon from '@assets/svgs/employee.svg';
import CalendarIcon from '@assets/svgs/calendar.svg';
import UserCalendarIcon from '@assets/svgs/time-off-calendar.svg';
import MegaphoneIcon from '@assets/svgs/megaphone-icon.svg';
import DocumentsIcon from '@assets/svgs/document.svg';
import DirectoryIcon from '@assets/svgs/directory-icon.svg';
import EmployeeTeamIcon from '@assets/svgs/employee-team.svg';
import PerformanceIcon from '@assets/svgs/performance-icon.svg';
import RoutesEnum from '@constants/routes.enum';
import usePath from '@hooks/usePath';
import { AuthContext } from '@contexts/AuthContext';
import { ColleagueDrawerProvider } from '@contexts/ColleagueDrawerContext';
import EmployeeNavBar from '@components/header/employee-nav-bar/EmployeeNavBar';
import useLocalStorage from '@hooks/useLocalStorage';
import { EmployeeProfileSettingsType, SettingsType } from '@models/settings/employee-profile-settings';
import { getUserViewSettings } from '@services/view-settings/employee-settings.service';
import useFetchData from '@hooks/useFetchData';
import useNotification from '@hooks/notification/useNotification';
import StateEnum from '@constants/state.enum';
import BottomBar from './bottom-bar/BottomBar';
import styles from './EmployeeLayout.module.scss';

const { Content, Sider } = Layout;

type EmployeeLayoutProps = {
  children: React.ReactNode;
};

const EmployeeLayout: React.FC<EmployeeLayoutProps> = ({ children }) => {
  const { useTypedTranslation } = makeTypedTranslation('common', 'notification');
  const { t: translate } = useTypedTranslation('common');
  const { t: translateNotification } = useTypedTranslation('notification');

  const router = useRouter();
  const { appPathPrefix } = usePath();
  const path = router.asPath.replace(appPathPrefix, '');

  const { user } = useContext(AuthContext);
  const [userSettings, setUserSettings] = useLocalStorage('settings', {} as EmployeeProfileSettingsType);
  const [errorNotification] = useNotification(StateEnum.ERROR, translateNotification('status.error'));

  function goTo(info: any) {
    router.push(`${appPathPrefix}${info.key.toString()}`);
  }

  // eslint-disable-next-line consistent-return
  const settingsFetcher = useCallback(async () => {
    if (!userSettings) return undefined;
    if (user?.view_settings_version === userSettings?.settings_version) return userSettings;

    const employeeSettings: SettingsType = await getUserViewSettings();

    setUserSettings({
      ...employeeSettings.settings,
      settings_version: employeeSettings.view_settings_version,
    });
  }, [userSettings]);

  useFetchData<SettingsType>(settingsFetcher, () => {
    errorNotification(undefined, translate('messages.error.something_went_wrong'));
  });

  const employeeMenu = (
    <div className={styles.menuContainer}>
      <Menu
        mode="inline"
        selectedKeys={[path]}
        defaultOpenKeys={['1', '2']}
        className="app-menu-container"
        onClick={goTo}
      >
        <Menu.Item
          icon={<HomeIcon />}
          key={RoutesEnum.EMPLOYEE_HOME}
          className="small-body-text"
          data-testid="home-route"
        >
          {translate('employee-menu.home')}
        </Menu.Item>
        <Menu.Item
          icon={<EmployeeIcon />}
          key={RoutesEnum.EMPLOYEE_PROFILE}
          className="small-body-text"
          data-testid="employee-profile-route"
        >
          {translate('employee-menu.profile')}
        </Menu.Item>
        <Menu.Item
          icon={<CalendarIcon />}
          key={RoutesEnum.EMPLOYEE_TIME_OFF}
          className="small-body-text"
          data-testid="employee-time-off-route"
        >
          {translate('employee-menu.time_off')}
        </Menu.Item>
        {user?.has_team && (
          <>
            <Menu.Item
              key={RoutesEnum.EMPLOYEE_TEAM_CALENDAR}
              icon={<UserCalendarIcon />}
              className="small-body-text"
              data-testid="employee-team-calendar"
            >
              {translate('employee-menu.team_calendar')}
            </Menu.Item>
            <Menu.SubMenu
              key="1"
              icon={<EmployeeTeamIcon />}
              className={styles.submenuItem}
              title={translate('employee-menu.my_team')}
              data-testid="employee-team-submenu"
            >
              <Menu.Item
                key={RoutesEnum.EMPLOYEE_TEAM_MEMBERS}
                className="small-body-text"
                data-testid="employee-team-members"
              >
                {translate('employee-menu.members')}
              </Menu.Item>
              <Menu.Item
                key={RoutesEnum.EMPLOYEE_TEAM_LEAVES}
                className="small-body-text"
                data-testid="employee-team-time-off"
              >
                {translate('employee-menu.team_time_off')}
              </Menu.Item>
              <Menu.Item
                key={RoutesEnum.EMPLOYEE_TEAM_BALANCE}
                className="small-body-text"
                data-testid="employee-team-balance"
              >
                {translate('employee-menu.team_balance')}
              </Menu.Item>
            </Menu.SubMenu>
          </>
        )}
        {!user?.has_team && (
          <Menu.Item
            icon={<UserCalendarIcon />}
            key={RoutesEnum.EMPLOYEE_CALENDAR}
            className="small-body-text"
            data-testid="calendar-route"
          >
            {translate('employee-menu.calendar')}
          </Menu.Item>
        )}
        <Menu.SubMenu
          key="2"
          icon={<PerformanceIcon />}
          className={styles.submenuItem}
          title={translate('menu.performance')}
          data-testid="performance-submenu"
        >
          <Menu.Item
            key={RoutesEnum.EMPLOYEE_PERFORMANCE_GOALS}
            className="small-body-text"
            data-testid="performance-goals-route"
          >
            {translate('employee-menu.my_goals')}
          </Menu.Item>
          {user?.has_team && (
            <Menu.Item
              key={RoutesEnum.EMPLOYEE_TEAM_PERFORMANCE_GOALS}
              className="small-body-text"
              data-testid="performance-team-goals-route"
            >
              {translate('employee-menu.team_goals')}
            </Menu.Item>
          )}
        </Menu.SubMenu>
        <Menu.Item
          icon={<OrgChartIcon />}
          key={RoutesEnum.EMPLOYEE_ORG_CHART}
          className="small-body-text"
          data-testid="org-chart-route"
        >
          {translate('employee-menu.org_chart')}
        </Menu.Item>
        <Menu.Item
          icon={<MegaphoneIcon />}
          key={RoutesEnum.EMPLOYEE_ANNOUNCEMENTS}
          className="small-body-text"
          data-testid="employee-news-route"
        >
          {translate('employee-menu.news')}
        </Menu.Item>
        <Menu.Item
          icon={<DocumentsIcon />}
          key={RoutesEnum.EMPLOYEE_COMPANY_DOCUMENTS}
          className="small-body-text"
          data-testid="employee-documents-route"
        >
          {translate('employee-menu.company_documents')}
        </Menu.Item>
        <Menu.Item
          icon={<DirectoryIcon />}
          key={RoutesEnum.EMPLOYEE_COLLEAGUES}
          className="small-body-text"
          data-testid="directory-route"
        >
          {translate('employee-menu.colleagues')}
        </Menu.Item>
      </Menu>
    </div>
  );

  return (
    <ColleagueDrawerProvider>
      <Layout>
        <EmployeeNavBar />
        <Layout>
          <Sider className={`scroll-bar ${styles.sider}`} collapsible breakpoint="lg" collapsedWidth={0} trigger={null}>
            {employeeMenu}
          </Sider>
          <Layout className={styles.main}>
            <Content>{children}</Content>
          </Layout>
        </Layout>
        <BottomBar />
      </Layout>
    </ColleagueDrawerProvider>
  );
};

export default EmployeeLayout;
