var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"6229947"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { RewriteFrames as RewriteFramesIntegration } from "@sentry/integrations";

const IGNORED_ERRORS = ['ResizeObserver loop limit exceeded'];

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;
Sentry.init({
  ignoreErrors: IGNORED_ERRORS,
  dsn: SENTRY_DSN,
  // Performance monitoring
  // Leaving the sample rate at 1.0 means that automatic instrumentation
  // will send a transaction each time a user loads any page or navigates
  // anywhere in your app, which is a lot of transactions. Sampling
  // enables you to collect representative data without overwhelming
  // either our system or our Sentry transaction quota.
  tracesSampleRate: 0.2,
  integrations: [
    new RewriteFramesIntegration({
      iteratee: (frame) => {
        frame.filename = decodeURI(frame.filename);
        return frame;
      },
    }),
  ],
  enabled: !!SENTRY_DSN,
  environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT || 'dev',
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
