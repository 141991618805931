import CustomEventTarget from '../utils/event-target-polyfill';

const makeInstance = () => {
  class EventBroker {
    // @ts-ignore
    eventTarget = new CustomEventTarget();

    dispatch(type: CustomEventTypes) {
      this.eventTarget.dispatchEvent(new Event(type));
    }
  }

  const eventBus = new EventBroker();
  return eventBus;
};

let instance: ReturnType<typeof makeInstance>;

const eventBus = {
  getInstance: () => {
    if (!instance) {
      instance = makeInstance();
    }
    return instance;
  },
};

export enum CustomEventTypes {
  EMPLOYEES_LIST_UPDATED = 'EMPLOYEES_LIST_UPDATED',
  EMPLOYEE_BASIC_INFO_UPDATED = 'EMPLOYEE_BASIC_INFO_UPDATED',
  EMPLOYEE_POSITIONS_UPDATED = 'EMPLOYEE_POSITIONS_UPDATED',
  EMPLOYEE_OTHER_INFO_UPDATED = 'EMPLOYEE_OTHER_INFO_UPDATED',
  EMPLOYEE_BALANCE_UPDATED = 'EMPLOYEE_BALANCE_UPDATED',
  EMPLOYEE_DOCUMENTS_UPDATED = 'EMPLOYEE_DOCUMENTS_UPDATED',
  LOCATIONS_UPDATED = 'LOCATIONS_UPDATED',
  POSITION_TITLES_UPDATED = 'POSITION_TITLES_UPDATED',
  DEPARTMENTS_UPDATED = 'DEPARTMENTS_UPDATED',
  EMPLOYEE_CHILDREN_INFO_UPDATED = 'EMPLOYEE_CHILDREN_INFO_UPDATED',
  REQUESTS_LIST_UPDATED = 'REQUESTS_LIST_UPDATED',
  BALANCE_LIST_UPDATED = 'BALANCE_LIST_UPDATED',
  TIME_OFF_TYPE_LIST_UPDATED = 'TIME_OFF_TYPE_LIST_UPDATED',
  ACCOUNT_UPDATED = 'ACCOUNT_UPDATED',
  USER_BASIC_INFO_UPDATED = 'USER_BASIC_INFO_UPDATED',
  USER_GENERAL_INFO_UPDATED = 'USER_GENERAL_INFO_UPDATED',
  USER_OTHER_INFO_UPDATED = 'USER_OTHER_INFO_UPDATED',
  USER_PERSONAL_DETAILS_UPDATED = 'USER_PERSONAL_DETAILS_UPDATED',
  USER_CHILDREN_INFO_UPDATED = 'USER_CHILDREN_INFO_UPDATED',
  USER_DOCUMENTS_UPDATED = 'USER_DOCUMENTS_UPDATED',
  USER_TIME_OFF_REQUESTS_UPDATED = 'USER_TIME_OFF_REQUESTS_UPDATED',
  TIME_OFF_TYPE_POLICIES_UPDATED = 'TIME_OFF_TYPE_POLICIES_UPDATED',
  ADD_TIME_OFF_TYPE_POLICY = 'ADD_TIME_OFF_TYPE_POLICY',
  HOLIDAYS_UPDATED = 'HOLIDAYS_UPDATED',
  USER_BALANCE_UPDATED = 'USER_BALANCE_UPDATED',
  USER_CALENDAR_UPDATED = 'USER_CALENDAR_UPDATED',
  PROFILE_BALANCES_UPDATED = 'PROFILE_BALANCES_UPDATED',
  ORG_CHART_UPDATED = 'ORG_CHART_UPDATED',
  CALENDAR_UPDATED = 'CALENDAR_UPDATED',
  ANNOUNCEMENTS_UPDATED = 'ANNOUNCEMENTS_UPDATED',
  ACCOUNT_INIT_DONE = 'ACCOUNT_INIT_DONE',
  SHOW_SUBSCRIPTION_BANNER = 'SHOW_SUBSCRIPTION_BANNER',
  UNSEEN_ADMIN_NOTIFICATIONS = 'UNSEEN_ADMIN_NOTIFICATIONS',
  UNSEEN_USER_NOTIFICATIONS = 'UNSEEN_USER_NOTIFICATIONS',
  FETCH_MORE_ADMIN_NOTIFICATIONS = 'FETCH_MORE_ADMIN_NOTIFICATIONS',
  CLOSE_ADMIN_NOTIFICATIONS = 'CLOSE_ADMIN_NOTIFICATIONS',
  TENANT_PHOTO_UPDATED = 'TENANT_PHOTO_UPDATED',
  TENANT_INFO_UPDATED = 'TENANT_INFO_UPDATED',
  DELETED_EMPLOYEE = 'DELETED_EMPLOYEE',
  COMPANY_DOCUMENTS_UPDATED = 'COMPANY_DOCUMENTS_UPDATED',
  USER_GOALS_UPDATED = 'USER_GOALS_UPDATED',
  TEAM_TOGGLE_PERFORMANCE_DETAILS = 'TEAM_TOGGLE_PERFORMANCE_DETAILS',
  TEAM_GET_GOALS = 'TEAM_GET_GOALS',
  TOGGLE_EDIT_PERFORMANCE = 'TOGGLE_EDIT_PERFORMANCE',
  ADMIN_FETCH_CYCLE_EMPLOYEES = 'ADMIN_FETCH_CYCLE_EMPLOYEES',
  CYCLES_UPDATED = 'CYCLES_UPDATED',
  PERFORMANCE_CYCLE_UPDATED = 'PERFORMANCE_CYCLE_UPDATED',
  TOGGLE_PERFORMANCE_DETAILS = 'TOGGLE_PERFORMANCE_DETAIL',
  EMAIL_SETTINGS_UPDATED = 'EMAIL_SETTINGS_UPDATED',
  EMPLOYEE_EMAIL_SETTINGS_UPDATED = 'EMPLOYEE_EMAIL_SETTINGS_UPDATED',
  ONBOARDING_EMAIL_UPDATED = 'ONBOARDING_EMAIL_UPDATED',
  ONBOARDING_WELCOME_MESSAGE_UPDATED = 'ONBOARDING_WELCOME_MESSAGE_UPDATED',
  ONBOARDING_PROFILE_TABS_UPDATED = 'ONBOARDING_PROFILE_TABS_UPDATED',
  WORKING_HOURS_UPDATED = 'WORKING_HOURS_UPDATED',
  RECRUITMENT_EMAILS_UPDATED = 'RECRUITMENT_EMAILS_UPDATED',
  RECRUITMENT_CANDIDATES_UPDATED = 'RECRUITMENT_CANDIDATES_UPDATED',
  RECRUITMENT_CANDIDATES_TOTALS_UPDATED = 'RECRUITMENT_CANDIDATES_TOTALS_UPDATED',
  RECRUITMENT_POSITIONS_UPDATED = 'RECRUITMENT_POSITIONS_UPDATED',
  RECRUITMENT_POSITION_UPDATED = 'RECRUITMENT_POSITION_UPDATED',
  RECRUITMENT_CANDIDATE_PROFILE_UPDATED = 'RECRUITMENT_CANDIDATE_PROFILE_UPDATED',
}

export default eventBus;
